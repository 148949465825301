.search_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
}
.label {
  color: #000;
  width: 90px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
#container {
  width: 100%;
  height: calc(100vh - 350px);
}
.amap-sug-result {
  z-index: 10240 !important;
}
#tipinput {
  width: calc(100% - 90px);
  height: 30px;
}